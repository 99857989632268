<template>
  <v-alert closable :type="alertType" class="alert ma-4" @toggle="close">
    <template v-slot:close>
      <app-icon name="close" @click="close" />
    </template>
    <span v-if="icon" data-notify="icon" :class="['alert-icon', icon]"></span>
    <div v-if="message" v-html="message"></div>
  </v-alert>
</template>

<script>
export default {
  name: "notification",
  props: {
    message: String,
    title: String,
    icon: String,
    type: {
      type: String,
      default: "info",
      validator: value => {
        let acceptedValues = ["info", "primary", "danger", "error", "warning", "success"];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    timeout: {
      type: Number,
      default: 5000,
      validator: value => {
        return value >= 0;
      },
    },
    timestamp: {
      type: Date,
      default: () => new Date(),
    },
    component: {
      type: [Object, Function],
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    closeOnClick: {
      type: Boolean,
      default: true,
    },
    clickHandler: Function,
  },
  computed: {
    hasIcon() {
      return this.icon && this.icon.length > 0;
    },
    alertType() {
      const typeMapper = { danger: "error" };
      return typeMapper[this.type] || this.type || "success";
    },
  },
  methods: {
    close() {
      this.$emit("close", this.timestamp);
    },
  },
  mounted() {
    if (!this.timeout) return;
    setTimeout(this.close, this.timeout);
  },
};
</script>
<style lang="scss">
.notifications {
  position: fixed;
  bottom: 0;
  left: 10px;
  z-index: 99999;
  width: 100%;
  max-width: 400px;
}
</style>
