import store from './store';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import i18n from './core/i18n';
import vuetify from './plugins/vuetify';
// import RouterPrefetch from "vue-router-prefetch";

// Plugins
import GlobalComponents from './plugins/globalComponents';
import NotificationsPlugin from './plugins/notification';
import amplitudePlugin from './plugins/amplitudePlugin';
import { constant } from '@/store/constant';
import * as Sentry from "@sentry/vue";

if (process.env.VUE_APP_NODE_ENV === 'production') {
  console.log('Production mode');
}

export default function createVueApp() {
  const app = createApp(App);

  if (process.env.VUE_APP_SENTRY === "enable") {
    tryEnableSentry(app, router);
  }

  app.use(store);
  app.use(router);
  app.use(i18n);
  // app.use(RouterPrefetch);
  app.use(vuetify);
  app.use(GlobalComponents);
  app.use(NotificationsPlugin);
  app.use(amplitudePlugin);

  if (process.env.VUE_APP_MODE === 'b2c') {
    store.dispatch(constant.plans.actions.FetchPrices).catch((e) => {
      console.error('error in promise prices', e);
    });
  }

  app.config.compatConfig = {
    MODE: 3, // Enable all Vue 2 compatible features
  };

  app.mount('#app');
}

function tryEnableSentry(app, router) {
  try {
    console.log(process.env.APP_VERSION);
    Sentry.init({
      app,
      attachProps: true,
      logErrors: true,
      tracing: true,
      tracingOptions: {
        trackComponents: true,
      },
      attachStacktrace: true,
      dsn: "https://bbd65ecdb53b44419dc23257186b5ca0@o343450.ingest.sentry.io/5424407",
      release: `loopcv-dashboard@${process.env.APP_VERSION}`,
      ignoreErrors: [
        "[Rewardful] Request failed",
        "Invalid referral",
        "[analytics.js] Failed to load Analytics.js",
        "Non-Error exception captured",
        "Non-Error promise rejection captured",
        "Network request failed",
        "Failed to fetch",
        "NetworkError",
        "A network error (such as timeout, interrupted connection or unreachable host) has occurred.",
        "prosemirror-model",
        "Failed to execute 'setStart' on 'Range'",
        "Navigation cancelled",
        "Avoided redundant navigation",
        "Redirected when going from",
        "Error sending segment performance metrics",
        "NavigationDuplicated",
      ],
      integrations: [
        Sentry.browserTracingIntegration({
          router,
        }),
        Sentry.extraErrorDataIntegration({ depth: 6 }),
        Sentry.captureConsoleIntegration({
          levels: ["error",],
        }),
      ],
      sampleRate: process.env.VUE_APP_NODE_ENV === "development" ? 1 : process.env.VUE_APP_MODE == "b2b" ? 0.3 : 0.05, // lower than 1 in production
      tracesSampleRate: process.env.VUE_APP_NODE_ENV === "development" ? 1 : 0.01, // lower than 1 in production
      tracePropagationTargets: ["api.loopcv.com", "app.loopcv.pro", "b2b-api-staging.loopcv.com"],
      environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
      normalizeDepth: 10,
    });

    // Global error handler for Vue.js
    app.config.errorHandler = (err, vm, info) => {
      Sentry.captureException(JSON.stringify(err));
    };

    // Global error handler for unhandled promise rejections
    window.addEventListener('unhandledrejection', event => {
      Sentry.captureException(JSON.stringify(event.reason));
    });

  } catch (e) {
    console.warn(e);
  }
}
