import qs from "qs";
import Cookies from "js-cookie";

import i18n from "@/core/i18n";
import { USER_TOKEN_COOKIE } from "@/assets/js/constants.js";
import store from "../store/index";

// type FetchOptions = {
//   queryParams?: {
//     [key: string]: string;
//   };
//   body?: {
//     [key: string]: any;
//   };
//   noAuth?: boolean;
// };
class Client {
  baseUrl = process.env.VUE_APP_B2B_BASEURL || "https://b2b-api.loopcv.com";
  /**
   * Fetch data from server
   *
   * @param {string} method
   * @param {string} path
   * @param {object} options { queryParams, auth, body, headers }
   * @memberof Fetch
   */
  async $fetch(method, path, options = {}) {
    let requestUrl = `${this.baseUrl}${path}`;

    if (process.env.VUE_APP_NODE_ENV === "development") {
      console.debug(`${method}: ${path}`, options);
    }

    if (options.queryParams) {
      requestUrl += `?${qs.stringify(options.queryParams, {
        arrayFormat: "brackets",
      })}`;
    }

    let body;
    if (options.body) {
      body = JSON.stringify(options.body);
    } else if (options.formData) {
      body = options.formData;
    }

    const headers = {
      Accept: "application/json",
      ...options.headers,
    };

    const encryptedPartnerId = Cookies.get("encryptedPartnerId");
    if (encryptedPartnerId) {
      headers["p"] = encryptedPartnerId;
    }

    if (!options.noAuth) {
      headers.Authorization = `Bearer ${Cookies.get(USER_TOKEN_COOKIE)}`;
    }
    if (!options.noContentType) {
      headers["Content-Type"] = "application/json";
    }

    return fetch(requestUrl, {
      method,
      headers,
      body,
    })
      .then(async res => {
        let responseContent;
        let message;

        const contentType = res.headers.get("content-type");
        if (contentType?.includes("application/csv")) {
          return res.blob();
        }
        if (!contentType || !contentType.includes("application/json")) {
          responseContent = await res.text();
        } else {
          responseContent = await res.json();
        }

        if (res.ok) {
          return responseContent;
        }
        
        if (res.status === 403) {
          // maybe the user had the app open for too long after idling and the firebase idToken could not refresh
          // TODO report the error
          message = i18n.global.t("Generic.Messages.Error.refresh")
        }

        // UnAuthorized
        if (res.status == 401) {
          message = i18n.global.t("Generic.Messages.Error.unauthorized", { email: store.getters.configs?.supportEmail })
        }

        // Method not allowed
        if (res.status == 405) {
          message = i18n.global.t("Generic.Messages.Error.notAllowed")
        }
        throw {
          statusCode: res.status,
          path,
          responseContent,
          response: res,
          message: message
        };
      })
      .catch(e => {
        if (e?.message?.includes("Failed to fetch")) {
          console.error("error: ", JSON.stringify(e));
          console.error(`failed to fetch, online: ${window.navigator?.onLine}`);
          return;
        }
        if (e.statusCode == 401) {
          console.error("error: ", JSON.stringify(e));
          console.error("no api key is present");
        } else {
          if (e.statusCode !== 409 && e?.message !== "template-linked-to-loop" ) {
            console.error("found error in fetch", JSON.stringify(e));
          }
        }
        throw e;
      });
  }
}

export default Client;
